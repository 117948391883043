import React, { useState } from 'react';
import api from '../api';
import * as Auth from "../AuthService";
import { Modal, Table, Tag, Button } from 'antd';
import "./QAHistory.css";
import "../index.less";

const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeLocationName: 'short'
}

const columns = [
    {
        title: 'Date',
        dataIndex: 'changed_at',
        key: 'date',
        render: (text) => new Date(text).toLocaleString('en-US', options),
        width: '40%'

    },
    {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },

]

function ModalHistory({ show, onClose, qaHistory }) {

    qaHistory.reverse();
    qaHistory.map((record) => {
        record.key = qaHistory.indexOf(record);
    });


    const checkChanges = (markups, prevMarkups) => {
        let result = [];
        prevMarkups.map((preMarkup) => {
            const item = markups.find(({ attr, value }) => attr === preMarkup.attr && value === preMarkup.value);
            if (item === undefined) {
                const markup = markups.find(markup => markup.attr === preMarkup.attr);
                if (markup) {
                    result.push([preMarkup, markup]);
                } else {
                    result.push([preMarkup, { attr: preMarkup.attr, value: "" }]);
                }
            }
        })
        markups.map((markup) => {
            const item = prevMarkups.find(({ attr, value }) => attr === markup.attr && value === markup.value);
            if (item === undefined) {
                const preMarkup = prevMarkups.find(preMarkup => preMarkup.attr === markup.attr);
                if (preMarkup === undefined) {
                    result.push([{ attr: markup.attr, value: "" }, markup]);
                }
            }
        })
        return result;
    }

    const handleExpandedRowRender = (record) => {
        let index = record.key + 1;
        let prevValues = undefined;
        if (index < qaHistory.length) {
            prevValues = qaHistory[index].markup;
        }
        if (prevValues) {
            let differences = checkChanges(record.markup, prevValues);
            return (
                <div style={{ "textAlign": "left" }}>
                    <h6 className="diff-title">Some changes have been made:</h6>
                    {differences.length !== 0 ? differences.map(([prevItem, item]) => (
                    <div key={item.attr}>
                        {prevItem.value !== "" && item.value !== "" ? 
                        <p className='diff-item'>
                            <span className="diff-attr-name">- {item.attr}:</span> <Tag color='red' style={{ marginLeft: '5px' }}>
                                {prevItem.value}
                            </Tag> changed to <Tag color='green' style={{ marginLeft: '5px' }}>
                                {item.value}
                            </Tag>
                        </p>
                        : prevItem.value === "" ? 
                        <p className='diff-item'>
                            <span className="diff-attr-name">- {item.attr}:</span> Added <Tag color='green' style={{ marginLeft: '5px' }}>
                                {item.value}
                            </Tag>
                        </p>:
                        <p className='diff-item'>
                            <span className="diff-attr-name">- {item.attr}:</span> Deleted <Tag color='red' style={{ marginLeft: '5px' }}>
                                {prevItem.value}
                            </Tag>
                        </p>
                        }
                    </div>
                    )) : <h6 className="diff-title">Nothing changes</h6>}
                </div>
            )
        } else {
            return (
                <div style={{ "textAlign": "left" }}>
                    <h6 className="diff-title">A new final has been added:</h6>
                    {record.markup.map((item) => (
                        <div key={item.attr}>
                            <p className='diff-item'>
                                <span className="diff-attr-name">- {item.attr}:</span><Tag color='green' style={{ marginLeft: '5px' }}>
                                    {item.value}
                                </Tag>
                            </p>
                        </div>
                    ))}
                </div>
            );
        }
    }



    return (
        <Modal width={'40%'} title="QA History" open={show} onCancel={onClose} footer={null}>
            <Table
                className='primary-table'
                columns={columns.map(col => ({
                    ...col,
                    align: 'left', 
                  }))}

                expandable={{
                    expandedRowRender: handleExpandedRowRender,
                    rowExpandable: (record) => record.status !== 'finals deleted',
                }}
                dataSource={qaHistory}
            />
        </Modal >


    )
}

export default function QAHistory(props) {

    const [showModal, setShowModal] = useState(false);
    const [qaHistory, setQaHistory] = useState([])

    const handleClose = () => setShowModal(false);

    const fetchData = () => {
        let item_id = props.item.item

        api.get(`/get/qa-history?item=${item_id}`, Auth.createConfig())
            .then(json => {
                setQaHistory(json.data);
            }).catch(error => {
                console.error('Error while receiving data:', error);
            });

    }

    const handleShow = () => {
        fetchData()
        setShowModal(true)
    }


    return (
        <>
            <Button type="primary" className='float-right btn-primary' onClick={handleShow}>History</Button>

            <ModalHistory show={showModal} onClose={handleClose} qaHistory={qaHistory} />
        </>

    );
}
