import decode from 'jwt-decode';

const token_item = 'hub_token';
const user_item = 'hub_user';

export function login(token, user) {
    sessionStorage.setItem(token_item, token);
    sessionStorage.setItem(user_item, JSON.stringify(user));
}

export function logout() {
    sessionStorage.removeItem(token_item);
    sessionStorage.removeItem(user_item);
}

export function getToken() {
    return sessionStorage.getItem(token_item);
}

export function getUser() {
    return JSON.parse(sessionStorage.getItem(user_item));
}

export function createConfig() {
    const token = getToken();
    return token
        ? { headers: {'Authorization': 'Bearer ' + getToken()} }
        : {}
}

export function loggedIn() {
    const token = getToken();
    return !!token && !isTokenExpired(token)
}

export function isTokenExpired(token) {
    try {
        const decoded = decode(token);
        return decoded.exp < Date.now() / 1000;
    }
    catch (err) {
        return false;
    }
}

export function extractRedirect() {
    const params = new URLSearchParams(window.location.search);
    return params.has('redirectTo') ? params.get('redirectTo').replace('%', '&') : '/';
}

export function redirectToLogin() {
    return `/login?redirectTo=${window.location.pathname+window.location.search}`;
}
