import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import React, { Component } from 'react';
import {FormGroup, FormControl, FormLabel, Image, Card } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import axios from 'axios';

import { properties } from '../properties.js';
import * as Auth from '../AuthService';
import NavMenu from "../NavbarComponent/Menu";
import Modal from "react-bootstrap/Modal";

require('promise.prototype.finally').shim();

class Reset extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            triedToSubmit: false
        }
    }

    checkRequired(value) {
        return (value.length === 0 && this.state.triedToSubmit) ? 'error' : null;
    }

    handleSubmit(e){
        e.preventDefault();
        if(this.state.email.length > 0) {
            axios.get(`${properties.apiUrl}/api/password-change-request?email=${this.state.email}&type=short&request=create`, {})
                .then((json) => {
                    alert('New password was sent to ' + this.state.email);
                    this.props.onClose()
                })
                .catch(error => alert(error.response.data))
                .finally(() => this.setState({
                    triedToSubmit: false
                }));
        } else {
            alert('Email is empty!')
        }
    }

    render() {
        return(
            <Modal show onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div>
                        <form onSubmit={e => this.handleSubmit(e)}>
                            <FormGroup controlId="email"
                                       validationState={this.checkRequired(this.state.email)}>
                                    <FormLabel column={7}>Email</FormLabel>

                                    <FormControl type="email" value={this.state.email}
                                                 onChange={e => this.setState({email: e.target.value})}/>
                            </FormGroup>
                            <Button style={{float:'right'}} type="submit" bsStyle="primary" loading={this.state.submitInProgress} onClick={e => this.handleSubmit(e)}>Send e-mail</Button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            triedToSubmit: false,
            submitInProgress: false,
            error: null,
            showModal: false
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({triedToSubmit: true});
        if (this.state.email.length > 0 && this.state.password.length > 0) {
            this.setState({submitInProgress: true});
            axios.post(`${properties.apiUrl}/api/login`, {
                    'login': this.state.email,
                    'password': this.state.password,
                }, {withCredentials: true})
                .then(json => {
                    Auth.login(json.data.jwt, json.data.user);
                    this.props.history.replace(Auth.extractRedirect());
                })
                .catch(error => this.setState({error: error.response.data, submitInProgress: false}));
        }
    }

    checkRequired(value) {
        return (value.length === 0 && this.state.triedToSubmit) ? 'error' : null;
    }

    render() {
        return (
            <div className="Login-root d-block mx-auto">
                <Card border="primary">
                    <Card.Body>
                        <div className='text-center'>
                            <Image style={{height: 100, marginLeft: -20}} src="/images/KBR_Logo_Registered_RGB.png"/>
                        </div>
                    <form onSubmit={e => this.handleSubmit(e)}>
                            <FormGroup controlId="email"
                                       validationState={this.checkRequired(this.state.email)}>
                                <FormLabel>Login</FormLabel>
                                <FormControl type="email" value={this.state.email}
                                             onChange={e => this.setState({email: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="password"
                                       validationState={this.checkRequired(this.state.password)}>
                                <FormLabel>Password</FormLabel>
                                <FormControl type="password" value={this.state.password}
                                             onChange={e => this.setState({password: e.target.value})}/>
                            </FormGroup>
                            { this.state.error && <span className="text-danger">{this.state.error}</span> }
                            <Button style={{marginBottom:10}} type="submit" bsStyle="primary" className="d-block mx-auto" loading={this.state.submitInProgress}>Log In</Button>
                            <a style={{color:`${properties.colors.oceanBlue}`}} onClick={() => this.setState({showModal:true})}>Forgot password?</a>
                        </form>
                    </Card.Body>
                </Card>
                {this.state.showModal && <Reset onClose={(status) => this.setState({showModal: false})}/>}
                {/*<NavMenu history={this.props/.history} logout={false}/>*/}
            </div>
        );
    }
}

export default Login;
