import React, { useState, useEffect, useCallback } from "react";
import {Card, Button, Tabs, Space, Tag} from "antd";
import axios from "axios";
import {properties} from '../properties';
import * as Auth from "../AuthService";
import Image from "react-bootstrap/Image";
import * as _ from "lodash";
import './QAManager.css';
import api from "../api";
import Metrics from "./Metrics";
import GeneralAttributes from "./GeneralAttributes";
import Description from "./Description";
import QAHistory from "./QAHistory";
import { CheckCircleOutlined } from "@ant-design/icons";
import QATable from "./QATable";
import "../index.less";
import "./QAManager.css";


export default function QAManager({item, getNext, tabKey}){

    // Information about the class
    const [attributes, setAttributes] = useState([]);
    const [classInfo, setClassInfo] = useState({});

    // Tags of the item
    const [tags, setTags] = useState([]);

    // Final attributes represent submitted (verified) attributes
    const [finalAttributesValues, setFinalAttributesValues] = useState({});
    const [finalAttributesPositions, setFinalAttributesPositions] = useState([]);
    
    // Annotated represent attributes that were annotated by the user in AnnotationManager
    const [annotatedValues, setAnnotatedValues] = useState({});
    const [annotatedPositions, setAnnotatedPositions] = useState([]);

    // Regex extraction represent attributes that were extracted by the regex
    const [regexExtractionValues, setRegexExtractionValues] = useState({});
    const [regexExtractionPositions, setRegexExtractionPositions] = useState([]);

    // LLMExtraction represent attributes that were extracted by the LLM
    const [llmExtractionValues, setLlmExtractionValues] = useState({});
    const [llmExtractionPositions, setLlmExtractionPositions] = useState([]);
    
    // Standard values represent attributes that were standardized (4MM -> 4mm). Applies to annotation, regex and llm values 
    const [standardValues, setStandardValues] = useState({});
    
    // Final values is attributes that in the last column of the table (Final Values) after standardization + Final Attributes
    const [finalValues, setFinalValues] = useState({});
    const [finalValuesPositions, setFinalValuesPositions] = useState([])
    
    // Displayed values are the values that are displayed in the description card
    const [displayedValuesWithPositions, setDisplayedValuesWithPositions] = useState([]);
    
    // Flag whether all attributes were verified
    const [allAttributesVerified, setAllAttributesVerified] = useState(false);

    useEffect(() => {
        if ("verified" in tags){
            setAllAttributesVerified(true);
        }
    }, [tags]);
        
    useEffect(() => {
        getClassInfo();
        getAnnotated();
        getRegexExtraction();
        getLLMExtraction();    
        getFinalAttributes();
        getTags();
        return () => {
            setStandardValues({});
            setFinalValues({});
            setFinalValuesPositions([]);
            setDisplayedValuesWithPositions([]);
            setAllAttributesVerified(false);
        }
    }, [item])

    useEffect(() => {
        getAnnotated();
        getTags();
    }, [tabKey])

    const getFinalValues = useCallback(() => {
        let _finalValues = {};
        for (let attr of attributes) {
            let attributeName = attr.attributeName;
            let finalAttributeValue = finalAttributesValues[attributeName];
            let annotatedValue = annotatedValues[attributeName];
            let extractedValue = regexExtractionValues[attributeName];
            let llmExtractedValue = llmExtractionValues[attributeName];
            
            let value = '';
            let field = '';

            if (finalAttributeValue !== undefined) {
                value = finalAttributeValue;
                field = 'finalAttribute';
            } else if (annotatedValue !== undefined) {
                value = annotatedValue;
                field = 'annotation';
            } else if (extractedValue !== undefined) {
                value = extractedValue;
                field = 'regexExtraction';
            } else if (llmExtractedValue !== undefined) {
                value = llmExtractedValue;
                field = 'llmExtraction';
            }
            
            _finalValues[attributeName] = { value, field };
        }
        standardizeFinalValues(_finalValues);
        getFinalValuesPositions(_finalValues);
    }, [attributes, finalAttributesValues, annotatedValues, regexExtractionValues, llmExtractionValues]);

    useEffect(() => {
        getFinalValues();
    }, [getFinalValues]);


    const displayAllAttributes = () => {
        setDisplayedValuesWithPositions(finalValuesPositions);
    }

    const getClassInfo = () => {
        api.get(`/classes/class?class=${item.class_name}`, Auth.createConfig())
            .then(json => {
                let attributes = json.data.attributes;
                json.data = _.omit(json.data, 'attributes');
                setAttributes(attributes);
                setClassInfo(json.data);
            })
            .catch(error => console.log(error));
    }

    
    const getAnnotated = () => {
        api.get(`/markup?item=${item.item}`, Auth.createConfig())
            .then(json => {
                let annotatedPositions = json.data[0].manualMarkups;
                let annotatedValues = {};
                for (let i = 0; i < annotatedPositions.length; i++) {
                    let attr = annotatedPositions[i].attr;
                    if (attr in annotatedValues){
                        annotatedValues[attr] = annotatedValues[attr] + ', ' + item.description.substr(annotatedPositions[i].from, annotatedPositions[i].length)
                    } else {
                        annotatedValues[attr] = item.description.substr(annotatedPositions[i].from, annotatedPositions[i].length)
                    }
                }
                setAnnotatedValues(annotatedValues);
                setAnnotatedPositions(annotatedPositions);
            })
            .catch(error => console.log(error))
    }

    const getRegexExtraction = () => {
        api.get(`/item/extraction?item=${item.item}`, Auth.createConfig())
            .then(json => {
                let regexExtractionValues = {};
                for(let i =0; i<json.data.items.length;i++){
                    let attr = json.data.items[i].attr;
                    if (attr in regexExtractionValues){
                        regexExtractionValues[attr] = regexExtractionValues[attr] + ', ' + json.data.items[i].value
                    } else {
                        regexExtractionValues[attr] = json.data.items[i].value
                    }
                }
                setRegexExtractionValues(regexExtractionValues);
                setRegexExtractionPositions(json.data.items);
            })
            .catch(error => console.log(error))
    }

    const getLLMExtraction = () => {
        api.get(`/item/llm-extraction?item_id=${item._id}`, Auth.createConfig())
            .then(json => {
                let llmExtraction = {};
                for(let i = 0; i < json.data.items.length; i++){
                    let attr = json.data.items[i].attr;
                    if (!(attr in llmExtraction)){
                        llmExtraction[attr] = [json.data.items[i].value]
                    } else if (!llmExtraction[attr].includes(json.data.items[i].value)){
                        llmExtraction[attr].push(json.data.items[i].value);
                    }

                }
                let llmExtractionValues = Object.fromEntries(
                    Object.entries(llmExtraction).map(([key, value]) => [key, value.join(", ")])
                );
                setLlmExtractionValues(llmExtractionValues);
                setLlmExtractionPositions(json.data.items);
            })
            .catch(error => console.log(error))
    }

    const getFinalAttributes = () => {
        api.get(`/final/all-attributes?item=${item.item}`, Auth.createConfig())
            .then(json => {
                let finalAttributesValues = json.data.final_attributes;
                setFinalAttributesValues(finalAttributesValues);
                if (Object.keys(finalAttributesValues).length > 0){
                    getFinalAttributesPositions(finalAttributesValues);
                }else{
                    setFinalAttributesPositions([]);
                }
            })
            .catch(error => console.log(error))
        }
    
    const getFinalAttributesPositions = (finalAttributesValues) => {
        let descriptionWithAttributes = {
            description: item.description,
            attributes: finalAttributesValues
        };
    
        api.post(`/matching-attribute-tokens`, descriptionWithAttributes, Auth.createConfig())
            .then(json => {
                let finalAttributesPositions = json.data.items
                setFinalAttributesPositions(finalAttributesPositions);
            })
            .catch(error => console.log(error))
    }

    
    
    const getFinalValuesPositions = (_finalValues) => {
        let positions = [];
        for (const attr in finalValues) {
            if (_finalValues[attr].field === 'finalAttribute') {
                positions.push(...finalAttributesPositions.filter(item => item.attr === attr))
            }else if (_finalValues[attr].field === 'annotation'){
                positions.push(...annotatedPositions.filter(item => item.attr === attr))
            } else if (_finalValues[attr].field === 'regexExtraction'){
                positions.push(...regexExtractionPositions.filter(item => item.attr === attr))
            } else if (_finalValues[attr].field === 'llmExtraction'){
                positions.push(...llmExtractionPositions.filter(item => item.attr === attr))
            }
        }
        setFinalValuesPositions(positions);
        return positions;
    }

    const standardizeFinalValues = (_finalValues) => {
        api.post(`/standard`, _finalValues, Auth.createConfig())
            .then(json => {
                let standardizedValues = {};
                let standardizedFinalValues = {};
                for(let attr in json.data.items){
                    let field = json.data.items[attr].field;
                    let value = json.data.items[attr].std;
                    
                    standardizedValues[attr] = value;
                    standardizedFinalValues[attr] = { value, field };
                }
                
                setStandardValues(standardizedValues);
                setFinalValues(standardizedFinalValues);
            })
            .catch(error => console.log(error))
    }

    const handleSelectCell = (attr, value, field) => {
        value = value !== '-' ? value : '';
        if(value && !finalAttributesValues[attr]) {
            let _finalValues = finalValues;
            if (attr in _finalValues && !_finalValues[attr].changed) {
                _finalValues[attr] = { value, field };
                standardizeFinalValues(_finalValues);
                getFinalValuesPositions(_finalValues);
                showAttribute(attr);
            }
        } else {
            setDisplayedValuesWithPositions([]);
        }
    }

    const showAttribute = (attr) => {
        setDisplayedValuesWithPositions(() => getFinalValuesPositions(finalValues).filter(item => item.attr === attr));
    }

    const getTags = () => {
        api.get(`/item/tags?item=${item.item}`, Auth.createConfig())
            .then(json => {
                setTags(json.data.tags);
            })
            .catch(error => console.log(error))
    }
    
    const saveFinalAttribute = (value, attr) => {
        let data = {
            item: item.item,
            finalAttributes: {
                [attr]: value
            }
        }
        console.log(data);
        api.post(`/save/final/attributes`, data, Auth.createConfig())
            .then(json => {
                getFinalAttributes();
                setDisplayedValuesWithPositions([]);
                getTags();
            })
            .catch(error => console.log(error))
    }

    const refreshFinalValue = (value, attr, field) => {
        let _finalValues = finalValues;
        _finalValues[attr] = {
            field: field,
            value: value
        };
        
        api.delete(`/delete/final/attributes?item=${item.item}&attributes=${attr}`, Auth.createConfig())
            .then(() => {
                getFinalAttributes();
                setDisplayedValuesWithPositions([]);
                getTags();
            })
            .catch(error => console.log(error))
    }

    const submitAll = () => {

        let data = {
            item: item.item,
            finalAttributes: Object.fromEntries(
                Object.entries(finalValues).map(([key, value]) => [key, value.value])
            )
        }
        console.log(data);
        api.post(`/save/final/attributes`, data, Auth.createConfig())
            .then(json => {
                getFinalAttributes();
                setAllAttributesVerified(true);
                getTags();
            })
            .catch(error => console.log(error))
    }

    const unsubmitAll = () => {
        let attributes_array = attributes.map(attr => attr.attributeName).join(',');
        api.delete(`/delete/final/attributes?item=${item.item}&attributes=${attributes_array}`, Auth.createConfig())
            .then(() => {
                getFinalAttributes();
                setAllAttributesVerified(false);
                getTags();
            })
            .catch(error => console.log(error))
    }
    return(
        <div>
            <ItemInfoCards item={item}
                            tags={tags}
                            displayedValuesWithPositions={displayedValuesWithPositions}
                            getNext={() => {getNext()}}
                            displayAllAttributes={() => displayAllAttributes()}
                            classInfo={classInfo}
                            finalValuesPositions={finalValuesPositions}/>
            <QATable 
                     item={item}
                     attributes={attributes}
                     handleSelectCell={(attr, value, field) => handleSelectCell(attr, value, field)}
                     saveFinalAttribute={(value, attr) => saveFinalAttribute(value, attr)}
                     refreshFinalValue={(value, attr, field) => refreshFinalValue(value, attr, field)}
                     showAttribute={(attr) => showAttribute(attr)}
                     standardValues={standardValues}
                     annotatedValues={annotatedValues}
                     regexExtractionValues={regexExtractionValues}
                     llmExtractionValues={llmExtractionValues}
                     finalValues={finalValues}
                     />
            <Space className="float-right">
                <QAHistory item={item} />
                <Button type="primary" className='float-right btn-primary' onClick={() => unsubmitAll()}>Unsubmit All</Button>
                {allAttributesVerified ? 
                    <Button type="primary" className='float-right btn-primary' style={{opacity: '0.5'}} onClick={() => {}}>Submit All</Button> :
                    <Button type="primary" className='float-right btn-primary' onClick={() => submitAll()}>Submit All</Button>
                }
            </Space>
        </div>
    );
}
    
function ItemInfoCards({item, tags, displayedValuesWithPositions, getNext, displayAllAttributes, classInfo, finalValuesPositions}){
    
    // State of displayed tab
    const [activeTab, setActiveTab] = useState('description');
    // Flag whether metrics tab content is visible (needed for correct calculation of metrics)
    const [visibleMetrics, setVisibleMetrics] = useState(false);


    const tabList = [
        { key: 'description', tab: 'Description' },
        { key: 'general-attributes', tab: 'General Attributes' },
        { key: 'metrics', tab: 'Metrics' },
    ];

    const contentList = {
        "description": <Description item={item}
                                    displayedValuesWithPositions={displayedValuesWithPositions}/>,
        "general-attributes": <GeneralAttributes item={item} classInfo={classInfo}/>,
        "metrics": <Metrics item={item} finalValuesPositions={finalValuesPositions} visibleMetrics={visibleMetrics}/>,
    };
    
    const handleTabChange = (key) => {
        if (key === "metrics") {
            setVisibleMetrics(true);
        }
        setActiveTab(key);  
    }

    return(
        <Card title={
            <Space>
                Item ID: {item.item} {tags && tags.map(tag => {
                        let color = null;
                        switch(tag){
                            case 'annotated':
                                color = 'blue';
                                break;
                            case 'verified':
                                color = 'green';
                                break;
                            case 'partially verified':
                                color = 'orange';
                                break;
                            default:
                                color = 'grey';
                                break;
                        }
                        return (
                            <Tag color={color} key={tag} style={{marginRight: 0}}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
            </Space>
        } extra={
            activeTab === 'description' ? <Space>
                <Button type="primary" className="btn-primary" onClick={() => {getNext(); setVisibleMetrics(false)}}>Get Next</Button>
                <Button type="primary" className="btn-primary" onClick={() => displayAllAttributes()}>Visualize</Button>
            </Space> : null
        }
        className="primary-card qa-card"
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={key => {
            handleTabChange(key);
        }}
        >
            {contentList[activeTab]}
        </Card>
    )
}