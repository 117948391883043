import React, {useEffect, useState} from "react";
import api from "../../api";
import * as Auth from "../../AuthService";
import {Table, Button, Space, Dropdown, Menu, Slider} from "antd";
import FileDownload from "js-file-download";
import ExamplesTableAttributeAnalyzer from "./ExamplesTableAttributeAnalyzer";
import * as _ from "lodash";
import "./TableAttributeAnalyzer.css";
import { properties } from "../../properties";
import { CheckCircleOutlined, CloseCircleOutlined, UnorderedListOutlined, EditOutlined } from '@ant-design/icons';
import SubmitActions from "./SubmitActions";
import "../../index.less";

function DropdownExamplesVariants({getExamples, getDownload}){
    const [count, setCount] = useState(50);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChange = (value) => {
        setCount(value);
    };

    const handleMenuClick = (e) => {
        if (e.key !== 'slider') {
          setOpen(false);
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick} style={{width: 200}}>
            <Menu.Item key="sample" onClick={() => getExamples(count, true, (state) => setLoading(state))}>Random {count} examples</Menu.Item>
            <Menu.Item key="first" onClick={() => getExamples(count, false, (state) => setLoading(state))}>First {count} examples</Menu.Item>
            <Menu.Item key="download" onClick={() => getDownload((state) => setLoading(state))}>Download all examples</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="slider">
                Count of examples: <Slider min={10} max={100} step={5} defaultValue={count} onChange={onChange} />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} open={open} onOpenChange={setOpen} trigger={['click']}>
          <Button type="primary" className="btn-primary" loading={loading} icon={<UnorderedListOutlined style={{color: 'white', fontSize: 20}}/>}></Button>
        </Dropdown>
    );
}

export default function TableAttributeAnalyzer({ dataSource, source, handleSearch }) {
    const [examples, setExamples] = useState([]);
    const [examplesPage, setExamplesPage] = useState(1);


    const getExamples = (action, count = 50, sample = false, setLoadingGetExamples) => {
        setLoadingGetExamples(true);
        api.get(`/statistics/attribute/examples?value=${action.value}&hash=${action.hash}&source=${action.source}&class=${action.class}&attribute=${action.attribute}&batch=${action.batch}&count=${count}&sample=${sample}`, Auth.createConfig())
            .then(json => {
                const _examples = json.data.examples.map(example => {
                    let description = example.description;
                    let _description = "";
                    if (example.positions && example.positions.length > 0) {
                        const positions = _.sortBy(example.positions, 'from');
                        let lastIndex = 0;
                        positions.map((position) => {
                            _description += description.substring(lastIndex, position.from);
                            _description += `<span style='background-color: ${properties.colors.yellow}';>${description.substr(position.from, position.length)}</span>`;
                            lastIndex = position.from + position.length;
                        });
                        _description += description.substr(lastIndex);
                    }
                    return {
                        item: example.item,
                        class: example.class,
                        description: _description.length > 0 ? _description : description,
                        value: example.value,
                        tokens: example.tokens,
                        source: example.source,
                    };
                });
                setExamples(_examples);
                setExamplesPage(1);
            })
            .catch(error => {
                console.log(error); 
                alert("Sorry, something went wrong.");
            })
            .finally(() => setLoadingGetExamples(false));
    }

    const downloadExamples = (action, setLoadingDownloadExamples) => {
        setLoadingDownloadExamples(true);
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        api.get(`/statistics/attribute/examples/download?value=${action.value}&hash=${action.hash}&source=${action.source}&class=${action.class}&attribute=${action.attribute}&batch=${action.batch}`, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename);
            })
            .catch(error => {
                console.log(error); 
                alert("Sorry, something went wrong.");
            })
            .finally(() => setLoadingDownloadExamples(false));
    }

    const columns = [
        {
            title: 'Submit',
            dataIndex: 'verifiedPercentage',
            width: 30,
            hidden: source !== 'finals',
            render: (verifiedPercentage) => {
                const percentColors = [
                    { pct: 0.0, color: { r: 0xff, g: 0x63, b: 0x47 } },
                    { pct: 50.0, color: { r: 0xf0, g: 0xe6, b: 0x8c } },
                    { pct: 100.0, color: { r: 0x00, g: 0x80, b: 0x00 } } ];
                
                const getColorForPercentage = (pct) => {
                    let lower = percentColors[0];
                    let upper = percentColors[percentColors.length - 1];
                    for (let i = 1; i < percentColors.length - 1; i++) {
                        if (pct <= percentColors[i].pct) {
                            lower = percentColors[i - 1];
                            upper = percentColors[i];
                            break;
                        }
                    }
                    let range = upper.pct - lower.pct;
                    let rangePct = (pct - lower.pct) / range;
                    let pctLower = 1 - rangePct;
                    let pctUpper = rangePct;
                    let color = {
                        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
                        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
                        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
                    };
                    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
                };
                return <p style={{
                    paddingBottom: 11,
                    margin: 0,
                    color: getColorForPercentage(verifiedPercentage),
                    fontSize: 54,
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}>●</p>;
            },
        },
        {
            title: 'Value',
            dataIndex: 'value',
            width: 300,
            sorter: (a, b) => a.value.localeCompare(b.value),
            render: (value) => <p style={{margin: 3, whiteSpace: 'break-spaces', wordBreak: 'break-word'}}>{value}</p>,
        },
        {
            title: 'Token',
            dataIndex: 'token',
            hidden: source !== 'finals',
            width: 200,
            sorter: (a, b) => a.token.localeCompare(b.token),
            render: (token) => <p style={{margin: 3, whiteSpace: 'break-spaces', wordBreak: 'break-word'}}>{token}</p>,
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            render: (frequency, record) => <span title={record.sources ? record.sources.map(obj => `${obj.source === "<empty>" ? "N/A" : obj.source}: ${obj.count} (${obj.percentage}%)`).join("\n") : ""}>{frequency[0]} ({frequency[1] > 1 ? frequency[1]: `<1`}%)</span>,
            width: 50,
            defaultSortOrder: "descend",
            sorter: (a, b) => a.frequency[1] - b.frequency[1],
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (action, record) => <Space direction="horizontal">
                                            {action.source === "finals" && <SubmitActions emptyFlag={record.value === "<empty>"} action={action} handleSearch={() => {handleSearch(); setExamples([])}}/>}
                                            <DropdownExamplesVariants 
                                                getExamples={(count, sample, setLoadingGetExamples) => getExamples(action, count, sample, setLoadingGetExamples)} 
                                                getDownload={(setLoadingDownloadExamples) => downloadExamples(action, setLoadingDownloadExamples)}
                                            />
                                        </Space>,
            width: 50,
        },
    ].filter(item => !item.hidden);

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Table
                showSorterTooltip={false}
                tableLayout="fixed"
                className="analysis-table primary-table"
                columns={columns}
                dataSource={dataSource}
                showSizeChanger={false}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                }}
            />
            {examples.length > 0 && <ExamplesTableAttributeAnalyzer 
                                            dataSource={examples} 
                                            page={examplesPage} 
                                            setPage={(page) => setExamplesPage(page)} />
            }
        </Space>
    )
}