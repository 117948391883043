import React, { useState, useEffect} from "react";
import {Card, Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import * as Auth from "../AuthService";
import * as _ from "lodash";
import api from "../api";
import "../index.less";
import './QAManager.css';

export default function Metrics({item, finalValuesPositions, visibleMetrics}) {
    const [tokenCompleteness, setTokenCompleteness] = useState(0);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (visibleMetrics) {
            getTokenCompleteness();
        }
    }, [visibleMetrics]);

    function getTokenCompleteness() {
        setLoading(true);
        let valuesToCheck = {
            "final_values": finalValuesPositions
        }
        api.post(`/metrics?item_id=${item._id}`, valuesToCheck, Auth.createConfig())
                .then(json => {
                    setTokenCompleteness(json.data.items.token_completeness)
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false));
    }

    return (
        <div>Token completeness: {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : _.round(tokenCompleteness, 2)}</div>
    )
}
