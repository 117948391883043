import React, {Component} from 'react';
import {Layout} from 'antd';
import NavMenu from "../NavbarComponent/Menu";

export class ProjectLayout extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout  style={{minHeight:"100vh"}}>
        <NavMenu />
        <Layout>
          <Layout>
          <Layout.Content style={{width: '100%', backgroundColor: '#ffffff'}}>
            {this.props.children}
          </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    ) 
  }
}
