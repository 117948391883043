import React from "react";
import './RuleDesigner.css'
import { Col, Form, FormGroup, FormLabel, Modal, ModalBody, Table } from "react-bootstrap";
import * as _ from 'lodash';
import Button from "react-bootstrap-button-loader";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { properties } from "../properties";
import * as Auth from "../AuthService";
import ModalHeader from "react-bootstrap/ModalHeader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import api from "../api";
import '../index.less';

class MarkerTable extends React.Component {

    constructor(props) {
        super(props);
        this.cell = React.createRef();
        this.state = {}
    }

    render() {
        let markers = (this.props.markers && typeof this.props.markers === 'object') ?
            this.props.markers : [];
        if (this.props.markers && typeof this.props.markers === 'string') {
            markers =
                this.props.markers.replace(/,/g, '\n').split('\n')
        }
        return (
            <div>
                <Table bordered className='Stats' style={{ marginTop: 10 }}>
                    <thead>
                        <tr>
                            <th>Source data</th>
                            <th>{this.props.type !== 'mapping' ? 'Rule template' : 'Mapped Class'}</th>
                            <th>Markers</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='align-middle'>{this.props.type !== 'mapping' ? this.props.token : this.props.template}</td>
                            <td className='align-middle'>{this.props.type !== 'mapping' ? this.props.template ? this.props.template : 'Add Rule template' : this.props.value}</td>
                            <td ref={this.cell} style={{ padding: 0, maxWidth: 250, wordBreak: 'break-all' }}>
                                {markers.length > 0 ? markers.join(',') : 'Add markers'}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

}

class Token extends React.Component {
    render() {
        return (
            <span className={"token token-" + this.props.match}
                dangerouslySetInnerHTML={{ __html: this.props.v }}
                onClick={() => this.props.handleSelect(this.props.v)} />);
    }

}

class Editor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenMarkers: false
        }
    }
    state = {};
    componentDidMount() {
        if (this.props.rule) {
            this.setState(this.props.rule);
            if (typeof this.props.rule['for'] === 'object') {
                this.setState({ attribute: this.props.rule.for.attribute })
            }
            this.setState({ key: this.props.rule.type === 'mapping' ? 'mapp' : 'reg' })
        }
    }

    checkRequired(value) {
        return (value && value.length !== 0);
    }

    handleSelect(token) {
        this.setState({ token: token })
    }

    handleSave(templateValue = '') {
        if (this.props.rule) {
            if (!this.state.name || !this.state.template || (this.state.key !== 'mapp' && !this.state.attribute)) {
                alert('Some fields are empty!')
            } else {
                let new_rule = {
                    _id: this.props.rule ? this.props.rule._id : '',
                    name: this.state.name,
                    type: this.state.key !== 'mapp' ? this.state.type : 'mapping',
                    template: templateValue ? templateValue : this.state.template,
                    value: this.state.value,
                    markers: this.state.markers,
                    token: this.state.token
                };
                this.props.handleSubmit(new_rule, 'rule', this.state.attribute)
            }
        }
        else {
            this.props.handleSubmit(this.state.template, 'template')
        }
    }

    compile() {
        api.get(`/compile/rule?rule=${this.state.template}`)
            .then(json => alert('Rule successfully compiled'))
            .catch(error => alert(error.response.data))
    }

    toggleFormControlMarkers = () => {
        this.setState(prevState => ({ isOpenMarkers: !prevState.isOpenMarkers }))
    }

    render() {

        let tokens = this.props.tokens ? this.props.tokens.map((v, i) => <Token handleSelect={(token) => this.handleSelect(token)} v={v} index={i} key={i} match={this.state.token === v ? 'exact' : 'none'} />) : [];

        return (
            <Modal size="lg" show onHide={() => this.props.onClose()}>
                <ModalHeader closeButton style={{ borderBottom: 'none', paddingBottom: 0, fontWeight: 'bold' }}>{this.props.data.title}</ModalHeader>
                <ModalBody>
                    <Tabs id="controlled-tab-example" activeKey={this.state.key}
                        onSelect={k => this.setState({ key: k })}>
                        <Tab eventKey="reg" title="Regular rules">
                            {this.props.rule && <div style={{ marginTop: 30 }}>
                                <FormGroup as={Row} controlId="description">
                                    <Col sm="2">
                                        <FormLabel column={3}>Name: </FormLabel>
                                    </Col>
                                    <Col sm="10">
                                        <Form.Control as="input" value={this.state.name}
                                            onChange={e => this.setState({ name: e.target.value })} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="description">
                                    <Col sm="2">
                                        <FormLabel column={3}>Attribute: </FormLabel>
                                    </Col>
                                    <Col sm="10">
                                        <Form.Control as="select" value={this.state.attribute}
                                            onChange={e => this.setState({ attribute: e.target.value })}>
                                            {this.props.attrs}
                                        </Form.Control>
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="description">
                                    <Col sm="2">
                                        <FormLabel column={3}>Description</FormLabel>
                                    </Col>
                                    <Col sm="10">
                                        <p>{this.props.description}</p>
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="description">
                                    <Col sm="2">
                                        <FormLabel column={3}>Tokens</FormLabel>
                                    </Col>
                                    <Col sm="10">
                                        {tokens}
                                    </Col>
                                </FormGroup>
                            </div>}
                            <div>{this.props.data.hint}</div>
                            <FormGroup as={Row} controlId="description">
                                <Form.Control style={{ width: '100%', margin: 10 }} as="input" value={this.state.template} isValid={this.checkRequired(this.state.template)} isInvalid={!this.checkRequired(this.state.template)}
                                    onChange={e => this.setState({ template: e.target.value })} />
                            </FormGroup>
                            <div>Markers:</div>
                            {
                                this.state.isOpenMarkers && (
                                    <div>
                                        <FormGroup as={Row} controlId="description" style={{ marginBottom: 0 }}>
                                            <Form.Control style={{ width: '100%', margin: 10 }} as="textarea" rows="3" value={this.state.markers} isValid={this.checkRequired(this.state.markers)} isInvalid={!this.checkRequired(this.state.markers)}
                                                onChange={e => this.setState({ markers: e.target.value })} />
                                        </FormGroup></div>
                                )
                            }
                            <Button onClick={this.toggleFormControlMarkers} className="primary-button btn-toggle-markers">{this.state.isOpenMarkers ? 'Close Markers' : 'Add Markers'}</Button>
                            <Button onClick={() => this.props.onClose()} type="default" bsStyle="secondary" className="primary-button d-block mx-auto float-left" loading={this.state.submitInProgress}>Cancel</Button>
                            <Button onClick={() => this.handleSave()} type="submit" bsStyle="primary" className="primary-button float-right" loading={this.state.submitInProgress}>Save</Button>
                            <Button onClick={() => this.compile()} style={{ marginRight: 5 }} className="primary-button float-right" loading={this.state.submitInProgress}>Compile rule</Button>
                        </Tab>
                        <Tab eventKey="mapp" title="Mapping rules">
                            <div style={{ marginTop: 30 }}>
                                {this.props.rule && <div>
                                    <FormGroup as={Row} controlId="description">
                                        <Col sm="2">
                                            <FormLabel column={3}>Name: </FormLabel>
                                        </Col>
                                        <Col sm="10">
                                            <Form.Control as="input" value={this.state.name}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row} controlId="description">
                                        <Col sm="2">
                                            <FormLabel column={3}>Description</FormLabel>
                                        </Col>
                                        <Col sm="10">
                                            <p>{this.props.description}</p>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row} controlId="description">
                                        <Col sm="2">
                                            <FormLabel column={3}>Tokens</FormLabel>
                                        </Col>
                                        <Col sm="10">
                                            {tokens}
                                        </Col>
                                    </FormGroup>
                                </div>}
                                <FormGroup as={Row} controlId="description">
                                    <Col sm="2">
                                        <FormLabel column={3}>Input mapping hint: </FormLabel>
                                    </Col>
                                    <Col sm="10">
                                        <Form.Control as="input" value={this.state.template}
                                            onChange={e => this.setState({ template: e.target.value })} />
                                    </Col>
                                </FormGroup>
                                <div>Input class name for mapping</div>
                                <FormGroup as={Row} controlId="description">
                                    <Form.Control style={{ width: '100%', margin: 10 }} as="input" value={this.state.value} isValid={this.checkRequired(this.state.template)} isInvalid={!this.checkRequired(this.state.template)}
                                        onChange={e => this.setState({ value: e.target.value })} />
                                </FormGroup>
                                <Button onClick={() => this.props.onClose()} type="default" bsStyle="secondary" className="primary-button d-block mx-auto float-left" loading={this.state.submitInProgress}>Cancel</Button>
                                <Button onClick={() => this.handleSave()} type="submit" bsStyle="primary" className="primary-button float-right" loading={this.state.submitInProgress}>Save</Button>
                                <Button onClick={() => this.compile()} style={{ marginRight: 5 }} className="primary-button float-right" loading={this.state.submitInProgress}>Compile rule</Button>
                            </div>
                        </Tab>
                    </Tabs>
                </ModalBody>
            </Modal>
        );
    }
}

class TestRow extends React.Component {

    render() {

        let description = this.props.row.token ?
            <td>
                {this.props.row.description.substr(0, this.props.row.description.indexOf(this.props.row.token))}
                <span style={{ backgroundColor: `${properties.shades.softSkyBlue}` }}> {this.props.row.description.substr(this.props.row.description.indexOf(this.props.row.token), this.props.row.token.length)}</span>
                {this.props.row.description.substr(this.props.row.description.indexOf(this.props.row.token) + this.props.row.token.length)}
            </td>
            : <td>{this.props.description} </td>;

        return (
            <tr>
                {description}
                <td>{this.props.row.token}</td>
                <td>{this.props.row.value}</td>
            </tr>
        );
    }

}

class TestArea extends React.Component {

    render() {
        let row = this.props.result.map((v, i) => <TestRow key={i} row={v} />);

        return (
            <div>
                {this.props.result.length > 0 ? <h4>Test area: </h4> : <h4>Rule isn't working</h4>}
                {this.props.result.length > 0 && <Table bordered className='Stats Test'>
                    <thead style={{ backgroundColor: '#d9d9d9', textAlign: 'center' }}>
                        <th>Description</th>
                        <th>Used token</th>
                        <th>Extracted data</th>
                    </thead>
                    <tbody>
                        {row}
                    </tbody>
                </Table>}
            </div>
        );
    }

}

class RuleDesigner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            source: [],
            template: '',
            markers: [],
            data: {},
            show: false,
            index: 0,
            test: false,
            attributes: []
        }

    }

    clear() {
        this.setState({
            template: '',
            markers: [],
            data: {},
            show: false,
            index: 0,
            _id: '',
            test: false,
            attribute: '',
            attributes: [],
            sample: []
        })
    }

    createRule() {
        api.get(`/token/examples?token=${this.props.rule['token'] ? this.props.rule['token'] : this.props.token}&class=${this.props.class}&batch=${this.props.batch}`, Auth.createConfig())
            .then(json => {
                this.setState({ sample: json.data.length > 0 ? json.data : [] })
            })
            .catch(error => console.log(error))
    }

    search() {
        api.get(`/search?batch=${this.props.batch}&itemId=${this.state.sample[this.state.index] ? this.state.sample[this.state.index].item : ''}`, Auth.createConfig())
            .then(json => {
                this.setState({ source: json.data.items[0], description: this.props.rule ? json.data.items[0].description.split('\n').join(',') : this.state.description })
            })
            .catch(error => {
                console.log(error);
                this.setState({ source: [] })
            });
    }

    getAttributes() {
        api.get(`/full-attributes?class=${this.props.class}`, Auth.createConfig())
            .then(json => this.setState({ attributes: this.filterAttributes(json.data.items) }))
            .catch(error => console.log(error));
    }

    filterAttributes(array) {
        return _.filter(array, (v) => v !== 'IGNORE LIST')
    }


    componentDidMount() {
        if (this.props.rule) {
            this.createRule();
            this.setState(this.props.rule);
            this.setState({ rule: this.props.rule });
            this.setState({ type: this.props.rule.type ? this.props.rule.type : 'regex' })
        }
        // else if (this.props.sample.length > 0) {
        //     this.setState({
        //         description: this.props.sample[this.state.index].description.split('\n').join(','),
        //         token: this.props.sample[this.state.index].used_token,
        //     });
        // }
        this.getAttributes();
        // this.search()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.index !== this.state.index && this.state.sample) {
            this.setState({
                description: this.state.sample[this.state.index] ? this.state.sample[this.state.index].description.split('\n').join(',') : '',
                token: this.state.sample[this.state.index] ? this.state.sample[this.state.index].used_token : '',
            });
            this.search()
        } else if (prevState.sample !== this.state.sample && this.state.sample.length > 0) {
            this.setState({
                description: this.state.sample[0].description ? this.state.sample[0].description.split('\n').join(',') : '',
                token: this.props.rule.token ? this.props.rule.token : this.state.sample[0].used_token,
                name: this.props.rule.name ? this.props.rule.name : '',
                attribute: this.props.rule ? this.props.rule.attribute : '',
                markers: this.props.rule ? this.props.rule.markers : [],
                template: this.props.rule.template ? this.props.rule.template : '',
                test: false,
                index: 0,
                rule: this.props.rule ? this.props.rule : ''
            });
            this.getAttributes();
            this.search()
        }
        if (prevProps.rule !== this.props.rule) {
            this.clear();
            this.createRule();
            if (this.props.rule) {
                this.setState(this.props.rule);
                this.setState({ rule: this.props.rule });
                this.setState({ type: this.props.rule.type ? this.props.rule.type : 'regex' })
            }
            this.getAttributes();
        }
        if (prevState.source.class_name !== this.state.source.class_name) {
            this.getAttributes();
        }
    }

    handleSubmit(value, from = '', attr = '') {
        if (from === 'rule') {
            this.save('', value, attr)
        } else if (from === 'template') {
            this.setState({ template: value })
        }
    }

    save(templateValue = '', rule = '', attr = '') {
        let new_rule;
        if (!rule) {
            new_rule = {
                _id: this.state._id,
                name: this.state.name,
                type: this.state.type,
                template: templateValue ? templateValue : this.state.template,
                value: templateValue ? templateValue : this.state.value,
                markers: this.state.markers,
                token: this.state.token,
                for: {
                    _class: this.props.class,
                    attribute: this.state.attribute
                }

            };
        } else {
            new_rule = rule;
            new_rule['_id'] = this.state._id;
            // new_rule['value'] = new_rule['template'];
            new_rule['for'] = {
                _class: this.props.class,
                attribute: attr
            };
            this.setState({ attribute: attr })

        }
        if (!(new_rule.template.indexOf('%marker%') > -1 && (!new_rule.markers || new_rule.markers.length === 0))) {
            api.post(`/save/rule`, new_rule, Auth.createConfig())
                .then(json => {
                    alert('Rule was saved');
                    this.setState({ show: false, rule: rule });
                    this.setState(rule);
                    this.setState((state) => {
                        return {
                            _id: json.data ? json.data : state._id
                        }
                    });
                    this.props.handleSave()
                })
                .catch(error => console.log(error))
        } else {
            alert('Please input markers')
        }
    }

    handleSave(templateValue = '') {
        if (!this.state.name || !this.state.template || !this.state.attribute) {
            alert('Some fields are empty!')
        } else {
            this.save(templateValue)
        }
    }

    getOtherSample() {
        this.setState({ index: this.state.index + 1 === this.state.sample.length ? 0 : this.state.index + 1 })
    }

    handleSelect(token) {
        this.setState({ token: token })
    }

    onDelete(marker) {
        let old = this.state.markers;
        old.splice(old.indexOf(marker), 1);
        this.setState({ markers: old })
    }

    add(from) {
        let data;
        if (from === 'template') {
            data = {
                title: this.state.template ? 'Edit Rule Template' : 'Add Rule Template',
                hint: 'Input Rule Template: ',
                value: this.state.template,
                from: 'template'
            }
        } else if (from === 'marker') {
            data = {
                title: 'Add new Marker',
                hint: 'Input Marker: ',
                from: 'markers'
            }
        }
        this.setState({ data: data, show: true })
    }

    testRule() {
        console.log(this.state)
        if (!this.state.name || (this.state.type !== 'mapping' && (!this.state.attribute || !this.state.template))) {
            alert('Some fields are empty!')
        } else {
            let new_rule = {
                name: this.state.name,
                template: this.state.template,
                token: this.state.token ? this.state.token : '',
                markers: this.state.markers,
                type: this.state.type ? this.state.type : 'regex',
                value: this.state.value,
                for: {
                    batch: this.props.batch,
                    _class: this.props.class,
                    attribute: this.state.attribute
                }
            };
            if (this.state.type === 'mapping' || (!(new_rule.template.indexOf('%marker%') > -1 && (!new_rule.markers || new_rule.markers.length === 0)))) {
                api.post(`/test/rule`, new_rule, Auth.createConfig())
                    .then(json => this.setState({ test: true, testResult: json.data }))
                    .catch(error => alert(error.response.data))
            }
            else {
                alert('Please input markers')
            }
        }
    }

    render() {
        let attrs = [];
        attrs.push(<option />);
        attrs.push(this.state.attributes.map((v, i) => <option key={i}>{v}</option>));

        let tokens = this.state.source.tokens ? this.state.source.tokens.map((v, i) => <Token handleSelect={(token) => null} v={v} index={i} key={i} match={this.state.token === v ? 'exact' : 'none'} />) : [];

        return (
            <div>
                <div className='row' style={{ marginTop: 10, marginLeft: 5 }}>
                    <div className='float-right'>
                        <Button className='primary-button buttons' style={{ marginRight: 10, marginLeft: 0 }} onClick={() => this.testRule()}>Test Rule</Button>
                        <Button className='primary-button buttons' style={{ marginRight: 10, marginLeft: 0 }} onClick={() => this.add('template')}>{this.state.rule ? this.state.rule.name ? 'Edit' : 'Add' : 'Add'} Rule</Button>
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <div>
                        <FormGroup as={Row} controlId="description">
                            <Col sm="2">
                                <FormLabel column={3}>Rule Name: </FormLabel>
                            </Col>
                            <Col sm="10">
                                <Form.Control disabled as="input" value={this.state.name} />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} controlId="description">
                            <Col sm="2">
                                <FormLabel column={3}>Attribute: </FormLabel>
                            </Col>
                            <Col sm="10">
                                <Form.Control disabled as="input" value={this.state.attribute}>
                                    {/*{attrs}*/}
                                </Form.Control>
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} controlId="description">
                            <Col sm="2">
                                <FormLabel column={3}>Description</FormLabel>
                                <Button className='primary-button buttons' onClick={() => this.getOtherSample()}>Get other sample</Button>
                            </Col>
                            <Col sm="10">
                                <p>{this.state.description}</p>
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} controlId="description">
                            <Col sm="2">
                                <FormLabel column={3}>Tokens</FormLabel>
                            </Col>
                            <Col sm="10">
                                {tokens}
                            </Col>
                        </FormGroup>
                    </div>
                    <div style={{ marginBottom: 5 }}>
                    </div>
                    <MarkerTable windowWidth={window.innerWidth}
                        token={this.state.token}
                        onDelete={(marker => this.onDelete(marker))}
                        type={this.state.type}
                        value={this.state.value}
                        template={this.state.template}
                        showMode={this.state.showMode}
                        markers={this.state.markers} />
                    <div className='float-right' style={{ marginBottom: 5 }}>
                    </div>
                    {this.state.test && <TestArea result={this.state.testResult} />}
                    {this.state.show && <Editor attrs={attrs}
                        tokens={this.state.source.tokens}
                        description={this.state.description}
                        rule={this.state.rule}
                        data={this.state.data}
                        handleSubmit={(value, from, attr) => this.handleSubmit(value, from, attr)}
                        onClose={() => this.setState({ show: false })} />}
                </div>
            </div>
        );

    }

}

export default RuleDesigner;
