import React, { useEffect, useState } from "react";
import api from "../../api";
import * as Auth from "../../AuthService";
import * as _ from "lodash";
import { Button, Select, Card, Space } from "antd";
import TableAttributeAnalyzer from "./TableAttributeAnalyzer";
import '../../index.less';
import "./AttributeAnalyzer.css";

export default function AttributeAnalyser({ batch, clsName }) {
    const [attributes, setAttributes] = useState([]);
    const [attributeToAnalyze, setAttributeToAnalyze] = useState("");
    const [attributeStatistics, setAttributeStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sizeOfClass, setSizeOfClass] = useState(0);
    const [countApprovedAtr, setCountApprovedAtr] = useState(0);
    const [openAnalysisTable, setOpenAnalysisTable] = useState(false);

    const sourceOptions = [
        {
            value: 'annotations',
            label: 'Annotations',
        },
        {
            value: 'rule-extractions',
            label: 'Regex Extractions',
        },
        {
            value: 'llm-extractions',
            label: 'LLM Extractions',
        },
        {
            value: 'finals',
            label: 'Finals',
        },
    ]
    const [source, setSource] = useState(sourceOptions[3].value ? sourceOptions[3].value : "");


    useEffect(() => {
        api.get(`/attributes?class=${clsName}`, Auth.createConfig())
            .then(json => {
                setAttributes(json.data.items);
                setAttributeToAnalyze(json.data.items[0].attributeName);
                setOpenAnalysisTable(false);
                setSizeOfClass(0);
            })
            .catch(error => console.log(error));
    }, [clsName]);

    const handleSearch = () => {
        setLoading(true);
        api.get(`/statistics/attribute?batch=${batch}&class=${clsName}&source=${source}&attribute=${attributeToAnalyze}`, Auth.createConfig())
            .then((response) => {
                let statistics = response.data.statistics;
                let size = response.data.size;
                let countApprovedAtr = response.data.approved;
                let data = statistics.map((stat) => ({
                    verifiedPercentage: stat.sources.verified ? _.round((stat.sources.verified / stat.frequency) * 100, 2) : 0,
                    value: stat.value,
                    token: stat.token,
                    frequency: [stat.frequency, _.round((stat.frequency / size).valueOf().toPrecision(4) * 100, 2)],
                    action: {
                        batch: batch,
                        class: clsName,
                        attribute: attributeToAnalyze,
                        source: source,
                        value: stat.value,
                        hash: stat.hash,
                    },
                    sources: Object.keys(stat.sources).map((key) => ({
                        source: key,
                        count: stat.sources[key],
                        percentage: _.round((stat.sources[key] / stat.frequency).valueOf().toPrecision(4) * 100, 2),
                    })),
                }));
                setAttributeStatistics(data);
                setSizeOfClass(size);
                setCountApprovedAtr(countApprovedAtr);
                setOpenAnalysisTable(true);
            })
            .catch((error) => {
                console.log(error);
                alert("Sorry, something went wrong.");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Card title={<p style={{ margin: 0 }}>Analysis for class: <b>{clsName}</b> (batch: <b>{batch}</b>)</p>} className="primary-card analysis-card">

            <Space direction="vertical">
                <div className="analysis-actions">
                    <Space>
                        Source:
                        <Select
                            placeholder="Select Source"
                            value={source}
                            style={{ width: 200, marginRight: 20 }}
                            options={sourceOptions}
                            onChange={(value) => { setSource(value); setOpenAnalysisTable(false) }}
                        />
                        Attribute:
                        <Select
                            placeholder="Select Attribute"
                            style={{ width: 200 }}
                            value={attributeToAnalyze}
                            options={attributes.map((attribute) => ({
                                value: attribute.attributeName,
                                label: attribute.attributeName,
                            }))}
                            onChange={(value) => { setAttributeToAnalyze(value); setOpenAnalysisTable(false) }}
                        />
                    </Space>
                    <div className="analysis-search">
                        <div>
                            {sizeOfClass > 0 && 
                                <p style={{marginBottom:0}}><b>Total items: {sizeOfClass}</b></p>
                            }
                            {sizeOfClass > 0 && countApprovedAtr != null ? 
                                <p style={{ marginBottom: 0 }}>
                                    Count approved attributes: <b>{countApprovedAtr}</b>
                                </p> 
                                : null
                            }
                        </div>
                    <Button
                        type="primary"
                        className="btn-primary"
                        disabled={!source || !attributeToAnalyze || loading}
                        onClick={handleSearch}
                        loading={loading}
                    >
                        Search
                    </Button>
                    </div>
                    </div>
                    <div>
                        {openAnalysisTable && <TableAttributeAnalyzer style={{ marginTop: 20 }} dataSource={attributeStatistics} source={source} handleSearch={handleSearch} />}
                    </div>

            </Space>
        </Card>

    )
}