import React, {useState, useEffect} from "react";
import api from "../../api";
import * as Auth from "../../AuthService";
import {Button, Input, Menu, Dropdown, Space} from "antd";
import {EditOutlined, CheckOutlined, ClearOutlined} from '@ant-design/icons';
import '../../index.less';

const EditField = ({clearAction, submitAction, value, loading, emptyFlag}) => {
    const [open, setOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleMenuClick = (e) => {
        if (e.key !== 'input') {
          setOpen(false);
        }
    }

    const handleSubmit = () => {
        submitAction(currentValue);
        setOpen(false);
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="input">
                <Input onKeyDown={(e) => e.key === 'Enter' ? handleSubmit() : null}
                    value={currentValue}
                    onChange={(e) => setCurrentValue(e.target.value)}
                />
            </Menu.Item>
            <Menu.Item key="submit_change">
                <Space style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Button className="btn-primary" type="primary" onClick={handleSubmit} style={{fontSize: 20, width: 64}} icon={<CheckOutlined />}/>
                    <Button className="btn-primary" type="primary" onClick={() => {
                        clearAction();
                        setOpen(false);
                    }} style={{fontSize: 20, width: 64}} icon={<ClearOutlined />}/>
                </Space>
            </Menu.Item>
        </Menu>
    );  
    return (
        <Dropdown disabled={emptyFlag} overlay={menu} open={open} onOpenChange={setOpen} trigger={['click']}>
          <Button type="primary" className="btn-primary" loading={loading} icon={<EditOutlined style={{color: 'white', fontSize: 20}}/>}></Button>
        </Dropdown>
    );
}


export default function SubmitActions({emptyFlag, action, handleSearch}) {
    const [loading, setLoading] = useState(false);

    const submitValue = (value) => {
        setLoading(true);
        let _action = {...action, old_value: action.value, value: value};
        api.post(`/statistics/attribute/final/change`, _action, Auth.createConfig())
            .then(json => {
                handleSearch();
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    const clearAction = () => {
        setLoading(true);
        api.post(`/statistics/attribute/final/clear`, action, Auth.createConfig())
            .then(json => {
                handleSearch();
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    return <>
        <EditField submitAction={(value) => submitValue(value)} value={action.value} loading={loading} clearAction={() => clearAction()} emptyFlag={emptyFlag}/>
    </>
}