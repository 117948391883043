import React, {useEffect, useState} from "react";
import {Col, Row, Radio, Popover, Button, message, Dropdown, Menu} from "antd";
import {DataCard} from "../Maturity Sources/components/DataCard";
import {capitalizeFirstLetter} from "../helpers";
import api from "../api";
import {ColoredColumns, ManualPagedTable} from "../Maturity Sources/Class Library Component";
import {AttributeViewer, SourceTag} from "./AttributeViewer";
import {EntityLinksDropdown} from "../DataAnalyzerComponent/DataAnalyzer";
import {CheckOutlined} from "@ant-design/icons";
import {SHOW_FIRST_IN_REGISTERS} from "../properties";
import FileDownload from "js-file-download";

export class AsyncDataSource {
    async values(page, limit, query, filters={}) {
        return [];
    }
}

export function Switcher ({value, setValue, options}) {

    const onChange = ({ target: { value } }) => {
        setValue(value);
    };

    return (
        <Radio.Group options={options} onChange={onChange} value={value} optionType="button" />
    )

}

class RegisterDataSource extends AsyncDataSource {

    constructor(_type) {
        super();
        this._type = _type
    }


    async values(page, limit, query, filters) {
        return api.post(
            `/register/${this._type}/get`,
            filters,
            {params: {page: page, limit: limit, q: query}}
        ).then(
            response => {
                return response.data.map(el => ({key: el._id, ...el}));
            }
        );
    }
}

function RegisterItemsTable ({_type, openModal, isModalVisible, saveFilters, saveQuery, setCount}) {
    const [dataSource, setDataSource] = useState(new RegisterDataSource(_type));
    const [disciplinesFilter, setDisciplinesFilter] = useState([])
    const [classesFilter, setClassesFilter] = useState([])
    const [filteredInfo, setFilteredInfo] = useState({'source': []})
    const [lockedFilter, setLockedFilter] = useState([])
    const [tagsValue, setTagsValue] = useState([])
    const [sourcesFilter, setSourcesFilter] = useState([])

    const getFilterValues = (field, action) => {
        api.get(`/register/${_type}/filter/${field}`)
            .then(json => {
                action(json.data)
            })
    }

    const mapDiscipline = disciplines => {
        const result = disciplines.map((d, i) => {
            return {
                key: i,
                text: d || '(empty)',
                value: d
            }
        })

        setDisciplinesFilter(result)
    }

    const mapClass = classes => {
        const result = classes.map((d, i) => {
            return {
                key: i,
                text: d || '(empty)',
                value: d
            }
        })

        setClassesFilter(result)
    }

    const mapIsLocked = values => {
        const result = values.map((d, i) => {
            return {
                key: i,
                text: d ? 'Locked' : 'Not Locked',
                value: d
            }
        })

        setLockedFilter(result)
    }

    const mapSources = values => {
        const result = values.map((d, i) => {
            return {
                key: i,
                text: d,
                value: d
            }
        })

        setSourcesFilter(result)
    }


    useEffect(() => {
        setDataSource(new RegisterDataSource(_type))
        getFilterValues("discipline", mapDiscipline)
        getFilterValues("class", mapClass)
        getFilterValues("isLocked", mapIsLocked)
        getFilterValues("source", mapSources)
        setTagsValue([])
    }, [_type])

    useEffect(() => {
        // setDataSource(new RegisterDataSource(_type, page, limit))
        if (!isModalVisible) {
            getFilterValues("discipline", mapDiscipline)
            getFilterValues("class", mapClass)
            getFilterValues("isLocked", mapIsLocked)
            getFilterValues("source", mapSources)
        }
    }, [isModalVisible])

    const columns = [
        {
            title: 'Discipline',
            dataIndex: 'discipline',
            filterSearch: true,
            // filterMode: 'tree',
            filteredValue: filteredInfo.discipline || null,
            filters: disciplinesFilter,
            render: (discipline) => <div style={{float: "left"}}>{discipline || '(empty)'}</div>
        },
        {
            title: 'Class',
            dataIndex: 'class',
            // filterMode: 'tree',
            filteredValue: filteredInfo.class || null,
            filters: classesFilter,
            filterSearch: true,
            render: (_class) => <div style={{float: "left"}}>{_class || '(empty)'}</div>
        },
        {
            title: 'Item',
            dataIndex: 'item',
            render: (item, row) => <EntityLinksDropdown tagNumber={row.tagNumber} material={item} batch={`${_type}-mapped-batch`} type={_type} children={<div className='beautiful-link' style={{float: "left"}}>{item}</div>}  />,

        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (description) => <div style={{float: "left"}}>{description}</div>
        },
        {
            title: 'Locked',
            dataIndex: 'isLocked',
            filters: lockedFilter,
            filteredValue: filteredInfo.isLocked || null,
            render: (isLocked) => isLocked ? <CheckOutlined style={{float: 'left', color: "#107E70"}} /> : null
        },
        {
            title: 'Actions',
            dataIndex: 'class',
            render: (_class, item) => <>
                <div className='beautiful-link' style={{cursor: 'pointer', float: 'left'}} onClick={() => openModal(item)}>Open</div>
            </>
        },
        {
            title: 'Sources',
            dataIndex: 'class',
            render: (_class, item) => <>
                <div className='beautiful-link' style={{cursor: 'pointer'}}>
                    <Popover placement={'left'} content={item.source && item.source.map(v => <SourceTag style={{float: "left"}} source={v}/>)}>?</Popover>
                </div>
            </>
        }
    ];

    if (_type === 'equipment') {
        columns.splice(4, 0, {
            title: 'Tag Number',
            dataIndex: 'tagNumber',
            render: (tagNumber) => <div style={{float: "left"}}>{tagNumber}</div>
        })
    }

    const coloredColumns = new ColoredColumns(
        columns,
        record => record?.isLocked ? 'rgba(162,199,226,.5)' : ''
    ).values();

    return (
        <ManualPagedTable tagsValue={tagsValue}
                          setTagsValue={setTagsValue}
                          showTags={true}
                          setCount={setCount}
                          saveQuery={saveQuery}
                          additionalFilter={{'source': sourcesFilter}}
                          filteredInfo={filteredInfo}
                          setFilteredInfo={setFilteredInfo}
                          saveFilters={saveFilters}
                          isModalVisible={isModalVisible}
                          mode={_type} size="small" columns={coloredColumns} asyncDataSource={dataSource} />

    )
}

export class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: SHOW_FIRST_IN_REGISTERS,
            isModalVisible: false,
            selected: {},
            filters: {},
            query: '',
            itemsCount: 0
        }
    }

    setValue = (newValue) => {
        this.setState({value: newValue})
    }

    openModal =  item => {
        this.setState({isModalVisible: true, selected: item})
    }

    export = (export_type) => {

        const config = {headers: {}}


        api.post(`/register/${this.state.value}/export`, {filters: this.state.filters, exportType: export_type, query: this.state.query}, config)
            .then(response => {
                message.info('Starting exporting the data! Could take a while...')
            })

    }
    render() {

        const menu = (
            <Menu>
                <Menu.Item onClick={() => this.export('doc_links')}>TAG_DOC_LINKS</Menu.Item>
                <Menu.Item onClick={() => this.export('property_run')}>TAG_PROPERTY_RUN</Menu.Item>
                <Menu.Item onClick={() => this.export('property_run_json')}>TAG_PROPERTY_RUN v2</Menu.Item>
                <Menu.Item onClick={() => this.export('property_run_json_v3')}>TAG_PROPERTY_RUN v3</Menu.Item>
                <Menu.Item onClick={() => this.export('property_run_json_v4')}>TAG_PROPERTY_RUN v4</Menu.Item>
                <Menu.Item onClick={() => this.export('run')}>TAG_RUN</Menu.Item>
                <Menu.Item onClick={() => this.export('consolidated_run')}>CONSOLIDATED_TAG_RUN</Menu.Item>
            </Menu>
        );

        const options = [
            {label: 'Material', value: 'material'},
            {label: 'Equipment', value: 'equipment'}
        ]


        const header = <div>
            {`${capitalizeFirstLetter(this.state.value)} Register (${this.state.itemsCount} items)`}

            <div style={{float: 'right', margin: 0}}>
                <Dropdown overlay={menu}
                          placement="bottom"
                >
                    <Button>Export</Button>
                </Dropdown>
            </div>
        </div>

        return (
            <Row>
                <Col span={24} style={{padding: 10}}>
                    <DataCard style={{paddingTop: 60}} title={header}>
                        Show: <Switcher options={options} value={this.state.value} setValue={this.setValue} />
                        <div style={{marginTop: 20}}>
                            <RegisterItemsTable openModal={this.openModal}
                                                saveFilters={filt => this.setState({filters: filt})}
                                                isModalVisible={this.state.isModalVisible}
                                                saveQuery={query => this.setState({query: query})}
                                                setCount={count => this.setState({itemsCount: count})}
                                                _type={this.state.value} />
                        </div>
                    </DataCard>
                </Col>
            <AttributeViewer mode={this.state.value} isModalVisible={this.state.isModalVisible} item={this.state.selected} close={() => this.setState({isModalVisible: false, selected: {}})} />
            </Row>
        );
    }

}
