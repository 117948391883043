import {Avatar, Row, Tooltip, Typography,Dropdown, Menu, Divider} from "antd";
import {OWNER, properties} from "../properties";
import React, {useContext, useEffect} from "react";
import {Link} from "./Menu";
import {HomeOutlined, UserOutlined} from "@ant-design/icons";
import {CurrentUserContext} from "../contexts/CurrentUserContext";
import {openLink} from "../layouts/MenuLayout";
import Auth from "../Auth";
import data from "../config/ui-config-chevron.json";

export function LogOut () {

    return  <Tooltip title="Log out" placement="bottom">
        <a onClick={() => Auth.logout()}>
            <img id="icon-logout"
                 src={process.env.PUBLIC_URL + '/images/logout-white2.png'}
                 alt='logout'

                 width={24} height={24} style={{alignSelf: 'center', marginRight: 20, marginLeft: 0}}/>
        </a>
    </Tooltip>
}

export function Logo() {

    let filename;

    switch (OWNER){
        case 'KBR':
            filename = '/images/KBR_Logo_Registered_White.png'
            break;
        case 'DIGATEX':
            filename = '/images/logo-digatex.png'
            break;
        default:
            filename = '/images/logo-digatex.png'
            break;
    }

    return (
        <>
            <img
                src={process.env.PUBLIC_URL + filename}
                alt="Logo"
                id="logo"
            />
            <div style={{position: 'relative'}}>
                <Typography.Title level={4} style={{color: 'white', margin: "0 0 12px", padding: "7.25px 0 0 32px", fontSize: 24}}>
                  {data.header.logo.title}
                </Typography.Title>
                <div
                    style={{
                        fontSize: '12px',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        lineHeight: 'initial',
                        marginBottom: '-4px',
                        color: 'lightgray'
                    }}>
                    <span>version: {properties.version}</span>
                </div>
            </div>
        </>
    )
}

export function HomeLink() {
    useEffect(() => {
        let iframe = document.querySelector('#jsd-widget');
        let iframeContent = iframe.contentDocument;
  
        const style = document.createElement('style');
        style.textContent = `
          #help-button {
            position: absolute;
            left: -9999px;
          }
        `;
        iframeContent.head.appendChild(style);
    }, []);
    return (
        <Link to="/">
            <Row>
                <Logo/>
                <HomeOutlined onClick={() => openLink('/')} id="home-button" style={{color: 'white', fontSize: '32px', paddingLeft: '32px'}}/>
            </Row>
        </Link>
    );
}

export function About() {
    return (
        <Typography.Text style={{color: 'white', fontStyle: 'italic'}}>
            AI by DIGATEX
        </Typography.Text>
    );
}

export function CurrentUserAvatar() {
    const user = useContext(CurrentUserContext);

    return (
        <div>
            <Avatar style={{backgroundColor: 'rgb(23,55,83)'}} size="default" icon={<UserOutlined id="user-icon" />}/>
            <span style={{color: 'white', margin: '1em', fontSize: 14}}>{user.email()}</span>
        </div>
    );
}

export function OtherResources() {

     let otherResources =  {
       "products": [
        {
            "id": "wb",
            "title": "Workbench"
          },
        {
          "id": "ih",
          "title": "Inventory"
        }
      ],
    }

    const overlay = (
        <Menu>
            {otherResources?.products?.map(el => <AnotherResourceItem key={el.id} {...el}/>)}
            {otherResources?.modules?.map(el => <AnotherResourceItem key={el.id} {...el}/>)}
        </Menu>
    )

    return (
        <>
            <Dropdown
                placement={"bottomLeft"}
                trigger={["hover"]}
                overlay={overlay}
                arrow
            >
                <img
                    style={{cursor: 'pointer'}}
                    alt={"other-products"}
                    width={24} height={24}
                    id={"other-products"}
                    src={"../images/dashboard-white.png"}
                />
            </Dropdown>
        </>
    );
}


function AnotherResourceItem({id, title, icon, url, ...rest}) {
    return (
        <Menu.Item {...rest}>
            <a href={url ?? process.env[`REACT_APP_${id.toUpperCase()}_LINK`]}>
                 {title}
            </a>
        </Menu.Item>
    );
}

