import './Users.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import React, { Component } from 'react';
import { Form, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import axios from 'axios';

import { properties } from '../properties.js';
import * as Auth from '../AuthService';
import Card from "react-bootstrap/Card";
import NavMenu from "../NavbarComponent/Menu";


require('promise.prototype.finally').shim();

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            company: '',
            operators: ['Santos', 'DIGATEX'],
            isOperator: false,
            isAdmin: false,
            triedToSubmit: false,
            submitInProgress: false
        };
    }

    componentDidMount() {
        axios.get(`${properties.apiUrl}/api/operators`, Auth.createConfig())
            .then(json => this.setState({operators: json.data}))
            .catch(error => console.log(error));
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({triedToSubmit: true});
        if (this.state.email.length > 0) {
            this.setState({submitInProgress: true});

            let permissions = [];
            if (this.state.isOperator) {
                permissions.push('operator');
            }
            if (this.state.isAdmin) {
                permissions.push('admin');
            }
            if(this.state.viewOnly){
                permissions.push('view');
            }

            axios.post(`${properties.apiUrl}/api/user`, {
                'email': this.state.email,
                'firstName': this.state.firstName,
                'lastName': this.state.lastName,
                'phone': this.state.phone,
                'company': this.state.company,
                'permissions': permissions
            }, Auth.createConfig())
                .then(() => {
                    alert(`User ${this.state.email} was successfully created`);
                    this.setState({
                        email: '',
                        firstName: '',
                        lastName: '',
                        phone: '',
                        company: '',
                        isOperator: false,
                        isAdmin: false,
                        viewOnly: false
                    });
                })
                .catch(error => alert(error.response.data))
                .finally(() => this.setState({
                    triedToSubmit: false,
                    submitInProgress: false
                }));
        }
    }

    checkRequired(value) {
        return (value.length === 0 && this.state.triedToSubmit) ? 'error' : null;
    }

    createSelectItems() {
        let items = [];
        items.push( <option selected key={0}></option>);
        if(this.state.operators) {
            for (let i = 0; i < this.state.operators.length; i++) {
                items.push( <option key={i+1} value={this.state.operators[i]}>{this.state.operators[i]}</option>);
            }
        }
        return items;
    }

    render() {
        return (
            <div className="Users-root d-block mx-auto">
                <Card border="primary">
                    <Card.Header>Create User</Card.Header>
                    <Card.Body>
                        <form className="User-form" onSubmit={e => this.handleSubmit(e)}>
                            <FormGroup controlId="email" className="required"
                                       validationState={this.checkRequired(this.state.email)}>
                                <FormLabel>Email</FormLabel>
                                <FormControl type="email" value={this.state.email}
                                             onChange={e => this.setState({email: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="firstName" className="required"
                                       validationState={this.checkRequired(this.state.firstName)}>
                                <FormLabel>First Name</FormLabel>
                                <FormControl type="text" value={this.state.firstName}
                                             onChange={e => this.setState({firstName: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="lastName" className="required"
                                       validationState={this.checkRequired(this.state.lastName)}>
                                <FormLabel>Last Name</FormLabel>
                                <FormControl type="text" value={this.state.lastName}
                                             onChange={e => this.setState({lastName: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="phone">
                                <FormLabel>Phone Number</FormLabel>
                                <FormControl type="text" value={this.state.phone}
                                             onChange={e => this.setState({phone: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="company">
                                <FormLabel>Company</FormLabel>
                                <FormControl as="select" value={this.state.company}
                                             onChange={e => this.setState({company: e.target.value})}>
                                    {this.createSelectItems()}
                                </FormControl>
                            </FormGroup>
                            <Button type="submit" bsStyle="primary" loading={this.state.submitInProgress}>Submit</Button>
                        </form>
                    </Card.Body>
                </Card>
                <NavMenu history={this.props.history}/>
            </div>
        );
    }
}

export default Users;
